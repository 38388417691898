import React from "react"
import HeaderBlock from "../../../elements/headingBlock"
import TextBlock from "../../../elements/textblock"
import ContentBlock from "../../contentBlock"

import "./twoColumnText.scss"

const TwoColumnText = data => {
  //page data
  const heading = data.heading

  const columnOne = data.columnOne

  const columnTwo = data.columnTwo

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="twoColumnText"
    >
      {/* <div className="twoColumnText-container"> */}
      {heading ? (
        <div className="twoColumnText-header">
          <HeaderBlock text={heading} level="2" headingClass="sectionTitle shortUnderline" />
        </div>
      ) : (
        ""
      )}

      {/*text area*/}
      <div className="twoColumnText-body">
        <TextBlock text={columnOne} wrapperClass="columnOne" />
        <TextBlock text={columnTwo} wrapperClass="columnTwo" />
      </div>
      {/* </div> */}
    </ContentBlock>
  )
}

export default TwoColumnText

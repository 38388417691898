import React from "react"
import CreateFormFields from "../utils"

const ComplaintForm = () => {
  const formFields = [
    {
      type: "text",
      name: "name",
      placeholder: "Julia Williams",
      label: "Your name*",
    },
    {
      type: "email",
      name: "contactEmail",
      placeholder: "you@example.com",
      label: "Contact email*",
    },
    {
      type: "phone",
      name: "phone",
      placeholder: "XXXX XXX XXX",
      label: "Phone*",
    },
    {
      type: "select",
      name: "subject",
      placeholder: "Subject",
      label: "Subject",
      options: [
        {
          value: "complaint",
          text: "Complaint",
        },
        {
          value: "other",
          text: "Other",
        },
      ],
    },
    {
      type: "textarea",
      name: "comment",
      placeholder: "Type your message",
      label: "Write a Comment",
    },
  ]

  return (
    <form>
      <CreateFormFields fields={formFields} />
      <div className="disclaimer">
        By submitting this form you agree to our terms and conditions and our
        Privacy Policy which explains how we may collect, use and disclose your
        personal information including to third parties.
      </div>
      <input
        type="submit"
        value="Contact"
        className="btn btn-primary btn-pill"
      />
    </form>
  )
}

export default ComplaintForm

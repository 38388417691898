import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ImageAndTextCards from "../../cards/imageAndTextCards"
import { convertToReadableDate, convertToExerpt } from "../../../../utils"

const WhitePapersList = props => {
  const query = useStaticQuery(graphql`
    query {
      allWpClaimsWhitePaperArticle {
        nodes {
          title
          content
          featuredImage {
            node {
              sourceUrl
            }
          }
          uri
          date
        }
      }
    }
  `)

  //create data array for Image and Cards Component
  //will need to add in categories too
  const cards = query.allWpClaimsWhitePaperArticle.nodes.map(node => {
    const convertedDate = convertToReadableDate(node.date)

    return {
      image: {
        sourceUrl: node.featuredImage.node.sourceUrl,
      },
      heading: node.title,
      text: convertToExerpt(node.content),
      link: {
        url: node.uri,
      },
      date: convertedDate,
    }
  })

  return <ImageAndTextCards cardsList={cards} />
}

export default WhitePapersList

import React from "react"
import HeaderBlock from "../../../elements/headingBlock"
import TextBlock from "../../../elements/textblock"
import ContentBlock from "../../contentBlock"

import "./fullWidthText.scss"

const FullWidthText = data => {
  //page data
  console.log(data)
  const heading = data.heading
  const headingTextAlign = data.headingTextAlign
  const textContent = data.textContent

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="fullWidthText"
    >
      {heading ? (
        <div className="fullWidthText-header">
          <HeaderBlock text={heading} level="1" headingClass={headingTextAlign === "center" ? "centerUnderline" : "shortUnderline"} />
        </div>
      ) : (
        ""
      )}

      {/*text area*/}
      <div className="fullWidthText-body">
        <TextBlock text={textContent} />
      </div>
    </ContentBlock>
  )
}

export default FullWidthText

import React from "react"

import HeadingBlock from "../../../elements/headingBlock"
import TextBlock from "../../../elements/textblock"
import ContentBlock from "../../contentBlock"
import Cards from "./cards"
import "./imageButtonCardsCarousel.scss"

const ImageButtonCardsCarousel = data => {
  const heading = data.heading
  const bodyText = data.bodyText
  const cards = data.cardsList
  // const carousel = document.getElementsByClassName(
  //   "imageButtonCardsCarousel-carousel"
  // )[0]
  // const carouselSlideDistance = 300

  function moveSliderRight(el) {
    const carousel = getCarouselElement(el)
    carousel?.scrollBy(300, 0)
  }

  function moveSliderLeft(el) {
    const carousel = getCarouselElement(el)
    carousel?.scrollBy(-300, 0)
  }

  function getCarouselElement(el) {
    return el
      ?.closest(".imageButtonCardsCarousel")
      ?.getElementsByClassName("imageButtonCardsCarousel-carousel")[0]
  }

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="imageButtonCardsCarousel"
    >
      <div className="imageButtonCardsCarousel-text">
        <HeadingBlock text={heading} />
        <TextBlock text={bodyText} />
        <div className="imageButtonCardsCarousel-controls">
          <button onClick={e => moveSliderLeft(e.target)}>
            <img src="/icons/chevron-left-white.svg" alt="" />
          </button>
          <button onClick={e => moveSliderRight(e.target)}>
            <img src="/icons/chevron-right-white.svg" alt="" />
          </button>
        </div>
      </div>
      <div className="imageButtonCardsCarousel-carousel">
        <Cards cards={cards} />
      </div>
    </ContentBlock>
  )
}

export default ImageButtonCardsCarousel

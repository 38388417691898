import * as React from "react"

import "./contentBlock.scss"

const ContentBlock = ({ className, contentBlockSettings, style, children }) => {
  let blockClass = className
  blockClass += getPaddingTop(contentBlockSettings?.paddingTop)
  blockClass += getPaddingBottom(contentBlockSettings?.paddingBottom)
  blockClass += getBackgroundColor(contentBlockSettings?.colourTheme)

  return (
    <section className={blockClass} style={style}>
      <div className={`${className}-container`}>{children}</div>
    </section>
  )
}

function getPaddingTop(size) {
  return " paddingTop-" + size
}

function getPaddingBottom(size) {
  return " paddingBottom-" + size
}

function getBackgroundColor(colourTheme) {
  switch (colourTheme) {
    case "white":
      return " whiteTheme"
    case "lightGrey":
      return " lightGreyTheme"
    case "grey":
      return " greyTheme"
    case "darkGrey":
      return " darkGreyTheme"
    default:
      return " whiteTheme"
  }
}

export default ContentBlock

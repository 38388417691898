import React from "react"

import ImageBlock from "../../../elements/imageBlock"
import TextBlock from "../../../elements/textblock"
import ContentBlock from "../../contentBlock"
import "./secondaryCTA.scss"

const CTA02 = data => {
  const testimonials = {
    quote: data?.quote,
    name: data?.name,
    position: data?.position,
  }

  const imageURL = data?.image?.sourceUrl

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="CTA02"
    >
      <div className="CTA02-body">
        <TextBlock text={testimonials.quote} wrapperClass="quote" />
        <div className="quoteSource">
          <TextBlock text={testimonials.name} wrapperClass="name" />
          <TextBlock text={testimonials.position} wrapperClass="position" />
        </div>
      </div>
      <div className="CTA02-image">
        <ImageBlock imageSrc={imageURL} />
      </div>
    </ContentBlock>
  )
}

export default CTA02

import React from "react"
import HeaderBlock from "../../../elements/headingBlock"
import ImageBlock from "../../../elements/imageBlock"
import TextBlock from "../../../elements/textblock"
import ContentBlock from "../../contentBlock"

import "./style.scss"

const Values = data => {
  const heading = data?.subtitle
  const title = data?.heading
  const subTitle = data?.bodyText
  const values = data?.values
    ? data?.values.map(value => {
      return {
        image: value?.image?.sourceUrl,
        heading: value?.heading,
        bodyText: value?.bodyText,
      }
    })
    : [
      {
        image: "",
        heading: "",
        bodyText: "",
      },
    ]

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="values"
    >
      <div className="values-header">
        <TextBlock text={heading} wrapperClass="leadingText" />
      </div>
      <div className="values-content">
        <div className="values-content-header">
          <HeaderBlock text={title} />
          <TextBlock text={subTitle} wrapperClass="subTitle" />
        </div>
        <div className="values-content-body">
          {values.map(value => (
            <div className="card">
              <ImageBlock imageSrc={value?.image} imageAlt={value?.heading} />
              <HeaderBlock text={value?.heading} level="3" />
              <TextBlock text={value?.bodyText} wrapperClass="bodyText" />
            </div>
          ))}
        </div>
      </div>
    </ContentBlock>
  )
}

export default Values

import React from "react"
import GetForm from "../../forms"
import ContentBlock from "../../contentBlock"

import "./displayForm.scss"

const DisplayForm = data => {
  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="displayForm"
    >
      <GetForm formID={data.displayForm} embedCode={data?.embedCode} />
    </ContentBlock>
  )
}

export default DisplayForm

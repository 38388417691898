import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import ImageBlock from "../../../elements/imageBlock"
import { useModal } from "../../../elements/modal/modalStore"
import ContentBlock from "../../contentBlock"
import "./teamList.scss"

const TeamList = props => {
  const [, setModalStore] = useModal()
  const query = useStaticQuery(graphql`
    query {
      allWpClaimsTeamMember {
        nodes {
          title
          cptTeams {
            contactDetails {
              email
              fieldGroupName
              linkedin
              phone
            }
            description
            fieldGroupName
            photo {
              sourceUrl
            }
            position
          }
        }
      }
    }
  `)

  const team = query.allWpClaimsTeamMember.nodes

  return (
    <ContentBlock
      contentBlockSettings={props.contentBlockSettings}
      className="teamList"
    >
      {team.map(member => (
        <button
          className="card"
          onClick={() => {
            setModalStore(modalCard(member, setModalStore))
          }}
        >
          <ImageBlock
            imageSrc={member?.cptTeams?.photo?.sourceUrl}
            imageAlt={member.title}
            wrapperClass="card-image"
          />
          <div className="card-body">
            <div className="name">{member.title}</div>
            <div className="position">{member?.cptTeams?.position}</div>
            <ImageBlock imageSrc="/icons/plus.svg" wrapperClass="icon" />
          </div>
        </button>
      ))}
    </ContentBlock>
  )
}

const modalCard = (member, setModalStore) => {
  return (
    <div className="teamModal">
      <div className="teamModal-container">
        <ImageBlock
          imageSrc={member?.cptTeams?.photo?.sourceUrl}
          imageAlt={member.title}
          wrapperClass="teamModal-image"
        />
        <div className="teamModal-body">
          <button
            onClick={() => {
              setModalStore("")
            }}
            className="teamModal-body-closeButton"
          >
            &times;
          </button>
          <div className="name">{member.title}</div>
          <div className="position">{member?.cptTeams?.position}</div>
          <div className="description">{member?.cptTeams?.description}</div>
        </div>
      </div>
    </div>
  )
}

export default TeamList

import React from "react"
import BecomeARepairerForm from "./becomeARepairer"
import BookACarForm from "./bookACar"
import ComplaintForm from "./complaint"
import JobApplicationForm from "./jobApplication"
import NewsletterForm from "./newsletter"
import GeneralContactForm from "./generalContact"
import EmbedForm from "./embedForm"

const GetForm = ({ formID, embedCode }) => {
  switch (formID) {
    case "becomeARepairer":
      return <BecomeARepairerForm />
    case "bookACar":
      return <BookACarForm />
    case "complaint":
      return <ComplaintForm />
    case "jobApplication":
      return <JobApplicationForm />
    case "newsletter":
      return <NewsletterForm />
    case "embed":
      return <EmbedForm embedCode={embedCode} />
    default:
      return <GeneralContactForm />
  }
}

export default GetForm

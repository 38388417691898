import React from "react"

import HeaderBlock from "../../../elements/headingBlock"
import PageLink from "../../../elements/pageLink"
import TextBlock from "../../../elements/textblock"
import ContentBlock from "../../contentBlock"
import "./cta04.scss"

const CTA04 = data => {
  const backgroundImage = data?.backgroundImage?.sourceUrl
  const heading = data?.heading
  const bodyText = data?.bodyText
  const button = data?.button

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="CTA04"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <HeaderBlock text={heading} />
      <TextBlock text={bodyText} />
      {button?.url && (
        <PageLink
          href={button.url}
          target={button.target}
          className="btn btn-primary btn-pill"
        >
          {button.title}
        </PageLink>
      )}
    </ContentBlock>
  )
}

export default CTA04

import React from "react"
import Card from "./card"

const Cards = ({ cards }) => {
  return (
    <div className="cards">
      {cards.map((card, index) => {
        return <Card key={index} card={card} />
      })}
    </div>
  )
}

export default Cards

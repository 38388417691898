import React from "react"
// import CreateFormFields from "../utils"

const EmbedForm = ({ embedCode }) => {
  console.log(embedCode)
  return (
    <div>
      <script type="text/javascript" src={embedCode}></script>
    </div>
  )
}

export default EmbedForm

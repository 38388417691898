import React from "react"
import HeaderBlock from "../../../elements/headingBlock"
import ImageBlock from "../../../elements/imageBlock"
import PageLink from "../../../elements/pageLink"

const Cards = ({ cards }) => {
  const cardsList = cards ? (
    cards.map((card, index) => <Card key={index} card={card} />)
  ) : (
    <div className="errorMessage">No cards available</div>
  )
  return <div className="cards">{cardsList}</div>
}

const Card = ({ card }) => {
  return (
    <PageLink href={card?.cardLink?.url} className="card">
      <ImageBlock
        imageSrc={card?.image?.sourceUrl}
        wrapperClass="buttonImage"
      />
      <HeaderBlock text={card.heading} level="3" />
      <ImageBlock
        imageSrc="/icons/arrow-right-gold.svg"
        alt=""
        wrapperClass="arrowIcon"
      />
    </PageLink>
  )
}

export default Cards

import React from "react"
import Cards from "../../cards/cards"
import ContentBlock from "../../contentBlock"

import "./textAndCards.scss"

const BorderlessCards = data => {
  const cards = data?.cardsList.map(card => {
    return {
      image: {
        src: card.image.sourceUrl,
      },
      heading: card.heading,
      bodyText: card.bodyText,
    }
  })

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="textandcards"
    >
      <Cards cards={cards} />
    </ContentBlock>
  )
}

export default BorderlessCards

import React from "react"

const NewsletterForm = () => (
  <form>
    <input type="text" name="firstName" placeholder="First name" />
    <input type="text" name="lastName" placeholder="Last name" />
    <input type="email" name="email" placeholder="Email address" />
    <input
      type="submit"
      value="Subscribe"
      className="btn btn-primary btn-pill"
    />
  </form>
)

export default NewsletterForm

import React from "react"

import ImageBlock from "../../../elements/imageBlock"
import PageLink from "../../../elements/pageLink"
import TextBlock from "../../../elements/textblock"
import ContentBlock from "../../contentBlock"
import "./quicklinks.scss"

const Quicklinks = data => {
  const quicklinks = data?.quicklinks

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="quicklinks"
    >
      {quicklinks?.map(quicklink => getQuicklinkButton(quicklink))}
    </ContentBlock>
  )
}

function getQuicklinkButton(quicklink) {
  return (
    <div className="quicklinks-button">
      <PageLink href={quicklink?.link?.url}>
        <TextBlock
          text={quicklink?.link?.title}
          wrapperClass="quicklinks-button-text"
        />
        <ImageBlock
          imageSrc={quicklink?.buttonImage?.sourceUrl}
          imageAlt={
            quicklink.buttonImage.alt
              ? quicklink.buttonImage.altText
              : quicklink.link.title
          }
          wrapperClass="quicklinks-button-image"
        />
      </PageLink>
    </div>
  )
}

export default Quicklinks

import React from "react"
import CreateFormFields from "../utils"

const BookACarForm = () => {
  const formFields = [
    {
      type: "text",
      name: "name",
      placeholder: "Julia Williams",
      label: "Your name*",
    },
    {
      type: "email",
      name: "contactEmail",
      placeholder: "you@example.com",
      label: "Contact email*",
    },
    {
      type: "phone",
      name: "phone",
      placeholder: "XXXX XXX XXX",
      label: "Phone*",
    },
    {
      type: "select",
      name: "wasyourfault",
      placeholder: "Was the accident your fault?",
      label: "Was the accident your fault?",
      options: [
        {
          value: "yes",
          text: "Yes",
        },
        {
          value: "no",
          text: "No",
        },
      ],
    },
    {
      type: "text",
      name: "postcode",
      label: "Postcode*",
    },
    {
      type: "text",
      name: "suburb",
      label: "Suburb",
    },
    {
      type: "text",
      name: "state",
      label: "State",
    },
    {
      type: "select",
      name: "vehicle-make",
      placeholder: "Select",
      label: "Vehicle Make*",
      options: [
        {
          value: "make 1",
          text: "Make 1",
        },
        {
          value: "make 2",
          text: "Make 2",
        },
      ],
    },
    {
      type: "text",
      name: "vehicle-model",
      label: "Vehicle Model*",
    },
    {
      type: "text",
      name: "year",
      label: "Car Year",
    },
    {
      type: "textarea",
      name: "comment",
      placeholder: "Type your message",
      label: "Write a Comment",
    },
  ]

  return (
    <form>
      <CreateFormFields fields={formFields} />
      <div className="disclaimer">
        By submitting this form you agree to our terms and conditions and our
        Privacy Policy which explains how we may collect, use and disclose your
        personal information including to third parties.
      </div>
      <input
        type="submit"
        value="Subscribe"
        className="btn btn-primary btn-pill"
      />
    </form>
  )
}

export default BookACarForm

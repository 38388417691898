import React from "react"

const CreateFormFields = ({ fields }) => {
  return (
    <>
      {fields.map(field => {
        const id = `${field?.type}--${field?.name}`

        return (
          <div className={id} key={id}>
            {/* create a label */}
            {field?.label && <label htmlFor={id}>{field?.label}</label>}
            <CreateField id={id} field={field} />
          </div>
        )
      })}
    </>
  )
}

const CreateField = ({ id, field }) => {
  // return a select field
  if (field?.type === "select") {
    return (
      <select name={field?.name} id={id}>
        <FormOptions options={field?.options} />
      </select>
    )
  }

  // return a textarea
  if (field?.type === "textarea") {
    return (
      <textarea
        name={field?.name}
        id={id}
        placeholder={field?.placeholder}
        rows={field?.rows ? field?.rows : 3}
      ></textarea>
    )
  }

  // return file upload
  if (field?.type === "file") {
    return (
      <div className="input-file">
        <input type={field?.type} id={id} name={field?.name} />
        <div className="input-file-instructions">
          Files must be in .doc, .docx or PDF and must be no larger than 4MB.
        </div>
      </div>
    )
  }
  // return default field
  return (
    <input
      type={field?.type}
      name={field?.name}
      placeholder={field?.placeholder}
      id={id}
    />
  )
}

const FormOptions = ({ options }) => {
  return (
    <>
      {options.map(option => {
        return <option value={option?.value}>{option?.text}</option>
      })}
    </>
  )
}

export default CreateFormFields

import React from "react"
import "./style.scss"

import HeaderBlock from "../../../elements/headingBlock"
import TextBlock from "../../../elements/textblock"
import PageLink from "../../../elements/pageLink"
import ContentBlock from "../../contentBlock"

const MainCTA = data => {
  const heading = data.heading
  const text = data.text
  const button = data.button

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="mainCTA"
    >
      <div className="mainCTA-content">
        <HeaderBlock text={heading} level="1" />
        <TextBlock text={text} wrapperClass="mb-6" />
        {button?.url && (
          <PageLink
            href={button.url}
            target={button.target}
            className="btn btn-pill btn-primary"
          >
            {console.log(button.url)}
            {console.log(button.target)}
            {console.log(button.title)}
            {button.title ? button.title : "Undefined"}
          </PageLink>
        )}
      </div>
    </ContentBlock>
  )
}

export default MainCTA

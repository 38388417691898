import React from "react"
import HeaderBlock from "../../../elements/headingBlock"
import ImageBlock from "../../../elements/imageBlock"
import TextBlock from "../../../elements/textblock"
import ContentBlock from "../../contentBlock"

import "./style.scss"

const InfoCards = data => {
  const cards = data?.cards

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="infoCards"
    >
      {cards
        ? cards.map(card => (
          <div className="card">
            <ImageBlock
              imageSrc={card?.image?.sourceUrl}
              imageAlt={card.heading}
              wrapperClass="card-thumbnail"
            />
            <div className="card-body">
              <HeaderBlock text={card.heading} level="3" />
              <TextBlock text={card.bodyText} />
            </div>
          </div>
        ))
        : ""}
    </ContentBlock>
  )
}

export default InfoCards

import React from "react"
//import CreateFormFields from "../utils"

const BecomeARepairerForm = () => {
  // const formFields = [
  //   {
  //     type: "text",
  //     name: "firstName",
  //     placeholder: "First name",
  //   },
  //   {
  //     type: "text",
  //     name: "lastName",
  //     placeholder: "Last name",
  //   },
  //   {
  //     type: "email",
  //     name: "email",
  //     placeholder: "Email address",
  //   },
  //   {
  //     type: "phone",
  //     name: "phone",
  //     placeholder: "Phone",
  //   },
  // ]

  return (
    <iframe title="Become a Repairer" src="https://slantagency.snapforms.com.au/form/mrmlKgJks1" width="100%" height="100%" scrolling="no" style={{ border: 0 + 'px', height: 277 + 'px' }}></iframe>
    // <form>
    //   <CreateFormFields fields={formFields} />
    //   <input
    //     type="submit"
    //     value="Subscribe"
    //     className="btn btn-primary btn-pill"
    //   />
    // </form>
  )
}

export default BecomeARepairerForm

import React from "react"

import Cta02 from "../components/content_blocks/CTA/CTA02"
import Cta03 from "../components/content_blocks/CTA/CTA03"
import Cta04 from "../components/content_blocks/CTA/CTA04"
import ContactFormCta from "../components/content_blocks/CTA/ContactFormCta"
//call to actions
import MainCTA from "../components/content_blocks/CTA/mainCTA"
import ImageAndTextCards from "../components/content_blocks/cards/imageAndTextCards"
import ImageButtonCards from "../components/content_blocks/cards/imageButtonCards"
//cards
import ImageButtonCardsCarousel from "../components/content_blocks/cards/imageButtonCardsCarousel"
import InfoCard from "../components/content_blocks/cards/infoCard"
//media
import Video from "../components/content_blocks/media/video"
import JobList from "../components/content_blocks/special/joblist"
import NewsList from "../components/content_blocks/special/newsList"
import Quicklinks from "../components/content_blocks/special/quicklinks"
//special
import Subheader from "../components/content_blocks/special/subheader"
import TeamList from "../components/content_blocks/special/teamList"
import Values from "../components/content_blocks/special/values"
import WhitePapersList from "../components/content_blocks/special/whitePaperList"
import BorderlessCards from "../components/content_blocks/standard/cards"
import DisplayForm from "../components/content_blocks/standard/displayForm"
import FullWidthText from "../components/content_blocks/standard/fullWidthText"
//standard
import TwoColumnText from "../components/content_blocks/standard/twoColumnText"

const AllLayouts = ({ layoutData }) => {
  const layoutType = layoutData?.fieldGroupName

  /**
   * Default component
   */
  const Default = () => (
    <div>
      In AllLayouts the mapping of this component is missing: {layoutType}
    </div>
  )

  /**
   * Mapping the fieldGroupName(s) to our components
   */
  const layouts = {
    ClaimsPage_Contentblocks_ContentBlocks_CallToAction: MainCTA,
    ClaimsPage_Contentblocks_ContentBlocks_VideoSection: Video,
    ClaimsPage_Contentblocks_ContentBlocks_Subheader: Subheader,
    ClaimsPage_Contentblocks_ContentBlocks_TwoColumnText: TwoColumnText,
    ClaimsPage_Contentblocks_ContentBlocks_FullWidthText: FullWidthText,
    ClaimsPage_Contentblocks_ContentBlocks_Cards: BorderlessCards,
    ClaimsPage_Contentblocks_ContentBlocks_Cta02: Cta02,
    ClaimsPage_Contentblocks_ContentBlocks_Cta03: Cta03,
    ClaimsPage_Contentblocks_ContentBlocks_Cta04: Cta04,
    ClaimsPage_Contentblocks_ContentBlocks_Quicklinks: Quicklinks,
    ClaimsPage_Contentblocks_ContentBlocks_ContactFormCta: ContactFormCta,
    ClaimsPage_Contentblocks_ContentBlocks_ImageButtonCardsCarousel:
      ImageButtonCardsCarousel,
    ClaimsPage_Contentblocks_ContentBlocks_ImageButtonCards: ImageButtonCards,
    ClaimsPage_Contentblocks_ContentBlocks_ImageAndTextCards: ImageAndTextCards,
    ClaimsPage_Contentblocks_ContentBlocks_TeamList: TeamList,
    ClaimsPage_Contentblocks_ContentBlocks_Infocards: InfoCard,
    ClaimsPage_Contentblocks_ContentBlocks_ValuesSection: Values,
    ClaimsPage_Contentblocks_ContentBlocks_NewsList: NewsList,
    ClaimsPage_Contentblocks_ContentBlocks_WhitePapersList: WhitePapersList,
    ClaimsPage_Contentblocks_ContentBlocks_DisplayForm: DisplayForm,
    ClaimsPage_Contentblocks_ContentBlocks_JobSearch: JobList,

    ClaimsService_Contentblocks_ContentBlocks_CallToAction: MainCTA,
    ClaimsService_Contentblocks_ContentBlocks_VideoSection: Video,
    ClaimsService_Contentblocks_ContentBlocks_Subheader: Subheader,
    ClaimsService_Contentblocks_ContentBlocks_TwoColumnText: TwoColumnText,
    ClaimsService_Contentblocks_ContentBlocks_FullWidthText: FullWidthText,
    ClaimsService_Contentblocks_ContentBlocks_Cta02: Cta02,
    ClaimsService_Contentblocks_ContentBlocks_Cta03: Cta03,
    ClaimsService_Contentblocks_ContentBlocks_Cta04: Cta04,
    ClaimsService_Contentblocks_ContentBlocks_Quicklinks: Quicklinks,
    ClaimsService_Contentblocks_ContentBlocks_ContactFormCta: ContactFormCta,
    ClaimsService_Contentblocks_ContentBlocks_ImageButtonCardsCarousel:
      ImageButtonCardsCarousel,
    ClaimsService_Contentblocks_ContentBlocks_ImageButtonCards:
      ImageButtonCards,
    ClaimsService_Contentblocks_ContentBlocks_Cards: BorderlessCards,
    ClaimsService_Contentblocks_ContentBlocks_ImageAndTextCards:
      ImageAndTextCards,
    ClaimsService_Contentblocks_ContentBlocks_TeamList: TeamList,
    ClaimsService_Contentblocks_ContentBlocks_Infocards: InfoCard,
    ClaimsService_Contentblocks_ContentBlocks_ValuesSection: Values,
    ClaimsService_Contentblocks_ContentBlocks_NewsList: NewsList,
    ClaimsService_Contentblocks_ContentBlocks_WhitePapersList: WhitePapersList,
    ClaimsService_Contentblocks_ContentBlocks_JobSearch: JobList,
    ClaimsService_Contentblocks_ContentBlocks_DisplayForm: DisplayForm,

    page_default: Default,
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = layouts[layoutType]
    ? layouts[layoutType]
    : layouts["page_default"]

  return <ComponentTag {...layoutData} />
}

export default AllLayouts

import React from "react"

import HeaderBlock from "../../../elements/headingBlock"
import TextBlock from "../../../elements/textblock"
import ContentBlock from "../../contentBlock"
import GetForm from "../../forms"
import "./contactFormCta.scss"

const ContactFormCta = data => {
  const heading = data?.heading
  const bodyText = data?.bodyText
  const formID = data?.form

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="newsletterCTA"
    >
      <div className="newsletterCTA-text">
        <HeaderBlock text={heading} level="2" headingClass="sectionTitle shortUnderline" />
        <TextBlock text={bodyText} />
      </div>
      <div className="newsletterCTA-form">{GetForm({ formID })}</div>
    </ContentBlock>
  )
}

export default ContactFormCta

import React from "react"

import HeadingBlock from "../../../elements/headingBlock"
import TextBlock from "../../../elements/textblock"
import ContentBlock from "../../contentBlock"
import Cards from "./cards"
import "./imageButtonCards.scss"

const ImageButtonCardsCarousel = data => {
  const heading = data.heading
  const bodyText = data.bodyText
  const cards = data.cardsList

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="imageButtonCards"
    >
      <div className="imageButtonCards-text">
        <HeadingBlock text={heading} />
        <TextBlock text={bodyText} />
      </div>
      <div className="imageButtonCards-cards">
        <Cards cards={cards} />
      </div>
    </ContentBlock>
  )
}

export default ImageButtonCardsCarousel

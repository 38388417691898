import React from "react"
import HeaderBlock from "../../../elements/headingBlock"
import ImageBlock from "../../../elements/imageBlock"
import TextBlock from "../../../elements/textblock"
import PageLink from "../../../elements/pageLink"
import ContentBlock from "../../contentBlock"

import "./style.scss"

const CTA03 = data => {
  const cardIcon = "/icons/tick-circle-gold.svg"
  const image = data?.image?.sourceUrl
  const link = data?.link
  const heading = data?.heading
  const bodyText = data?.bodyText
  const metrics = data?.metrics

  return (
    <ContentBlock
      contentBlockSettings={data.contentBlockSettings}
      className="CTA03"
    >
      <div className="CTA03-container">
        <div className="CTA03-body">
          <PageLink
            href={link.url}
            target={link.target}
            className="btn btn-primary btn-pill"
          >
            {link.text}
          </PageLink>
          <HeaderBlock text={heading} level="2" />
          <TextBlock text={bodyText} wrapperClass="bodyText" />
          <div className="metrics">
            {metrics.map(metric => (
              <div className="card">
                <ImageBlock imageSrc={cardIcon} />
                <div className="value">{metric.value}</div>
                <div className="title">{metric.title}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="CTA03-image">
          <ImageBlock imageSrc={image} />
        </div>
      </div>
    </ContentBlock>
  )
}

export default CTA03

import React from "react"

import PageLink from "../../../elements/pageLink"
import ContentBlock from "../../contentBlock"
import "./imageAndTextCards.scss"

const ImageAndTextCards = ({ contentBlockSettings, cardsList }) => {
  const cardList = cardsList ? (
    cardsList.map(card => (
      <div className="card">
        <div className="card-thumbnail">
          <img src={card?.image?.sourceUrl} alt={card.heading} />
          {card.category ? (
            <div className="card-category">{card.category}</div>
          ) : (
            ""
          )}
        </div>
        <div className="card-body">
          <h4>{card.heading}</h4>
          <div className="card-text">{card.text}</div>
        </div>
        <div className="card-footer">
          {card?.cardLink ? (
            <div className="card-footer-link">
              <PageLink href={card.cardLink.url}>
                <img src="/icons/arrow-right-gold.svg" alt="" />
              </PageLink>
            </div>
          ) : (
            ""
          )}
          {card.date ? <div className="card-footer-date">{card.date}</div> : ""}
        </div>
      </div>
    ))
  ) : (
    <div className="errorMessage">No cards available</div>
  )

  return (
    <ContentBlock
      contentBlockSettings={contentBlockSettings}
      className="imageAndTextCards"
    >
      {/*cards*/}
      <div className="cards">{cardList}</div>
    </ContentBlock>
  )
}

export default ImageAndTextCards

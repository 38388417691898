import React from "react"
import Video from "../../../elements/video"
import "./style.scss"

const VideoBlock = data => {
  // get section data

  const video = {
    url: data.videoUrl,
    title: data.videoTitle,
  }

  // return section layout
  return (
    <section className="videoBlock">
      <div className="videoBlock-container">
        <Video
          videoSrcURL={video.url}
          videoTitle={video.title}
          wrapperClass="videoBlock-video"
        />
      </div>
    </section>
  )
}

export default VideoBlock

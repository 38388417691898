import React from "react"
import HeaderBlock from "../../../elements/headingBlock"
import TextBlock from "../../../elements/textblock"
import ImageBlock from "../../../elements/imageBlock"
import PageLink from "../../../elements/pageLink"

const Card = ({ card }) => {
  // return card link if a link is provided
  if (card?.link?.url)
    return (
      <PageLink href={card.link.url} target={card.link.target} className="card">
        <CardContent card={card} />
      </PageLink>
    )

  // return a standard card element otherwise
  return (
    <div className="card">
      <CardContent card={card} />
    </div>
  )
}

const CardContent = ({ card }) => {
  return (
    <>
      <div className="card-media">
        {card?.image ? (
          <ImageBlock imageSrc={card.image.src} imageAlt={card.image.alt} />
        ) : null}
      </div>
      <div className="card-content">
        {card?.heading ? <HeaderBlock text={card.heading} level="3" /> : null}
        {card?.bodyText ? <TextBlock text={card.bodyText} /> : null}
      </div>
    </>
  )
}

export default Card

import React, { useState, useEffect } from "react"
import HeaderBlock from "../../../elements/headingBlock"
import ImageBlock from "../../../elements/imageBlock"
import TextBlock from "../../../elements/textblock"
import ContentBlock from "../../contentBlock"

import "./style.scss"

const JobList = data => {
  const heading = "Current Roles"
  const bodyText =
    "Find the right job for you no matter what it is that you do."

  const jobs = [
    {
      title: "Office Manager",
      location: "Perth",
      url: "#",
    },
    {
      title: "Spray Painter",
      location: "Brisbane",
      url: "#",
    },
    {
      title: "Spray Painter",
      location: "Perth",
      url: "#",
    },
    {
      title: "Panel Beater",
      location: "Melbourne",
      url: "#",
    },
    {
      title: "Panel Beater",
      location: "Perth",
      url: "#",
    },
    {
      title: "Customer Service",
      location: "Perth",
      url: "#",
    },
  ]

  const errorMessage = "No jobs match your search criteria."

  const [displayedJobs, setDisplayedJobs] = useState(jobs)
  const [textSearch, setTextSearch] = useState("")
  const [locationSearch, setLocationSearch] = useState("")

  const locationList = [...new Set(jobs.map(job => job.location))]

  useEffect(() => {
    filterJobs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationSearch, textSearch])

  function filterByLocation(array) {
    return array.filter(arrayItem => {
      if (arrayItem.location.indexOf(locationSearch) !== -1) {
        return true
      } else return false
    })
  }

  function filterByTitle(array) {
    return array.filter(arrayItem => {
      if (arrayItem.title.indexOf(textSearch) !== -1) {
        return true
      } else return false
    })
  }

  function filterJobs() {
    let filteredJobs = jobs

    // filter job listing by location
    filteredJobs = filterByLocation(filteredJobs)

    // filter filtered job listing by title
    filteredJobs = filterByTitle(filteredJobs)

    setDisplayedJobs(filteredJobs)
  }

  return (
    <ContentBlock
      className="jobListing"
      contentBlockSettings={data.contentBlockSettings}
    >
      <div className="jobListing-header">
        <HeaderBlock text={heading} />
        <TextBlock text={bodyText} />
      </div>
      <div className="jobListing-search">
        <select onChange={e => setLocationSearch(e.target.value)}>
          <option value="" selected>
            Select a Location
          </option>
          {locationList.map(location => {
            return <option value={location}>{location}</option>
          })}
        </select>
        <input
          type="search"
          placeholder="Search Positions"
          onChange={e => setTextSearch(e.target.value)}
        />
      </div>
      <div className="jobListing-list">
        {displayedJobs.length === 0 && (
          <div className="errorMessage">{errorMessage}</div>
        )}
        {displayedJobs.map(job => {
          return (
            <div className="jobListing-list-item job">
              <div className="job-heading">
                <div className="job-heading-title">{job.title}</div>
                <div className="job-heading-location">{job.location}</div>
              </div>
              <ImageBlock imageSrc="/icons/chevron-right-gold.svg" alt="" />
            </div>
          )
        })}
      </div>
    </ContentBlock>
  )
}

export default JobList
